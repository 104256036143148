import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import PortalFunctionList from "./PortalFunctionList";
import owlIcon from "../../../assets/images/owl.png";
import shape from "../../../assets/images/shape/circle-shape1.png";
import { unwrapNode } from "../../../utils/markdown-unwrapper";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";

const PortalFunctions = () => {
  const data = useStaticQuery(graphql`
    query {
      portalSection: markdownRemark(
        frontmatter: { de: { template: { eq: "portal-section" } } }
      ) {
        frontmatter {
          de {
            description
            title
          }
          en {
            description
            title
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { title, description } = unwrapNode(data.portalSection, language);

  return (
    <section
      className="process-area pb-70 pt-100 px-2"
      style={{ backgroundColor: "#f1f8fb" }}
    >
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img
              src={owlIcon}
              alt={t("howitworks:portal-subtitle")}
              width={30}
            />{" "}
            {t("howitworks:portal-subtitle")}
          </span>
          <h2 id="portal">{title}</h2>
          <p>
            <DangerousMarkdownContent content={description} />
          </p>
        </div>
        <PortalFunctionList />
      </div>

      <div className="circle-shape1">
        <img src={shape} alt="about" />
      </div>
    </section>
  );
};

export default PortalFunctions;
