import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import React from "react";

/**
 *
 * Resolution of fluidImage must be 16:9
 *
 */
const Screen = ({ fluidImage }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "screen.png" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 99) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <div className="position-relative">
      <div
        style={{
          top: 0,
          bottom: "5%",
          left: -2,
          right: -2,
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Img
          style={{
            height: "90%",
            width: "90%",
          }}
          imgStyle={{
            objectFit: "contain",
          }}
          fluid={fluidImage}
        />
      </div>
      <Img
        style={{
          height: "100%",
          width: "100%",
          maxWidth: 800,
        }}
        imgStyle={{
          objectFit: "contain",
        }}
        fluid={data.file.childImageSharp.fluid}
      ></Img>
    </div>
  );
};

export default Screen;
