import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import FaqCallToAction from "../timewise-custom/components/CallToAction/FaqCallToAction";
import PortalFunctions from "../timewise-custom/components/PortalFunctions/PortalFunctions";
import PageBanner from "../components/Common/PageBanner";
import Layout from "../timewise-custom/components/App/Layout";
import Navbar from "../timewise-custom/components/App/Navbar";
import SEO from "../timewise-custom/components/App/SEO";
import SavingProcess from "../timewise-custom/components/SavingProcess/SavingProcess";

const HowItWorks = () => {
  const { t } = useI18next();

  return (
    <Layout>
      <SEO title={t("navigation:how-it-works")} />
      <Navbar />
      <PageBanner
        pageTitle={t("navigation:how-it-works")}
        homePageText={t("navigation:home")}
        homePageUrl="/"
        activePageText={t("navigation:how-it-works")}
      />
      <SavingProcess />
      <PortalFunctions />
      <FaqCallToAction />
    </Layout>
  );
};

export default HowItWorks;
