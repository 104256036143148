import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import SavingProcessSteps from "../SavingProcess/SavingProcessSteps";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import { unwrapNode } from "../../../utils/markdown-unwrapper";
import owlIcon from "../../../assets/images/owl.png";

const SavingProcess = () => {
  const data = useStaticQuery(graphql`
    query {
      savingProcess: markdownRemark(
        frontmatter: { de: { template: { eq: "saving-process-section" } } }
      ) {
        frontmatter {
          de {
            description
            title
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          en {
            description
            title
          }
        }
      }
    }
  `);
  const { language, t } = useI18next();
  const { title, description, image } = unwrapNode(
    data.savingProcess,
    language
  );

  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center position-relative">
          <div className="col-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={owlIcon} alt={t("about:title")} width={30} />
                {t("howitworks:subtitle")}
              </span>
              <h2 id="saving-process">{title}</h2>
              <p>
                <DangerousMarkdownContent content={description} />
              </p>
              <SavingProcessSteps />
            </div>
          </div>

          <div
            className="how-its-work-image position-absolute d-flex justify-content-start"
            style={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Img
              style={{
                height: "100%",
                width: "100%",
                maxWidth: 800,
                opacity: 0.1,
              }}
              imgStyle={{
                objectFit: "contain",
              }}
              fluid={image.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SavingProcess;
