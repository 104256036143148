import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import Screen from "../Screen/Screen";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";

const SavingProcessSteps = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___order], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "saving-process" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                description
                title
                isScreenshot
                image {
                  childImageSharp {
                    fluid(maxWidth: 350, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                description
                title
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const savingSteps = markdownUnwrapper(data, language);

  return (
    <div className="inner-box">
      {savingSteps.map((savingStep, idx) => (
        <div key={idx} className="single-item">
          <div className="count-box">{idx + 1}</div>
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-12 col-md-6">
              <h3>{savingStep.title}</h3>
              <div className="text-secondary">
                <DangerousMarkdownContent content={savingStep.description} />
              </div>
            </div>
            {savingStep.image && (
              <div
                className="py-3 col-12 col-md-6"
                style={{
                  width: 200,
                  maxWidth: 350,
                }}
              >
                {!savingStep.isScreenshot ? (
                  <Img
                    style={{
                      height: "100%",
                      width: "100%",
                      maxWidth: 800,
                    }}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                    fluid={savingStep.image.childImageSharp.fluid}
                  />
                ) : (
                  <Screen fluidImage={savingStep.image.childImageSharp.fluid} />
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SavingProcessSteps;
