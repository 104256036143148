import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import DangerousMarkdownContent from "../DangerousMarkdownContent/DangerousMarkdownContent";
import { markdownUnwrapper } from "../../../utils/markdown-unwrapper";

const PortalFunctions = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___de___priority], order: ASC }
        filter: { frontmatter: { de: { template: { eq: "portal-feature" } } } }
      ) {
        edges {
          node {
            frontmatter {
              de {
                description
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 350, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              en {
                description
                title
              }
            }
          }
        }
      }
    }
  `);
  const { language } = useI18next();
  const features = markdownUnwrapper(data, language);

  return (
    <div className="row">
      {features.map(({ title, description, image }, idx) => (
        <div className="col-lg-4 col-md-6" key={idx}>
          <div className="single-process-box">
            <div className="number">{idx + 1}</div>
            <div className="image">
              <Img
                alt={title}
                style={{
                  height: "100%",
                  width: "100%",
                  maxHeight: 175,
                }}
                imgStyle={{
                  objectFit: "contain",
                }}
                fluid={image.childImageSharp.fluid}
              />
            </div>
            <h3>{title}</h3>
            <div>
              <DangerousMarkdownContent content={description} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PortalFunctions;
